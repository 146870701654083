import request from '@/utils/request'

// 查询客户的卡
export function findSimCards(data) {
  return request({
    url: `/customer/sim_cards`,
    method: 'post',
    data
  })
}

// 查询 sim_card 卡基本信息
export function findSimCardBaseInfo(id) {
  return request({
    url: `/sim_cards/${id}/base_info`,
    method: 'get'
  })
}

// 查询 sim_card 卡语音白名单信息
export function findSimCardVoiceWhiteList(id) {
  return request({
    url: `/sim_cards/${id}/query_voice_whitelist`,
    method: 'get'
  })
}

// 编辑 sim_card 卡语音白名单
export function editVoiceWhiteList(id, data) {
  return request({
    url: `/sim_cards/${id}/edit_voice_whitelist`,
    method: 'post',
    data
  })
}

// 查询 sim_card 设备与用户
export function findSimCardDeviceAndUser(id) {
  return request({
    url: `/sim_cards/${id}/device_and_user`,
    method: 'get'
  })
}

// 查询 sim_card 当前套餐
export function findSimCardCurrentPackage(id) {
  return request({
    url: `/sim_cards/${id}/current_package`,
    method: 'get'
  })
}

// 查询 sim_card 后续套餐
export function findSimCardFuturePackages(id) {
  return request({
    url: `/sim_cards/${id}/future_packages`,
    method: 'get'
  })
}

// 查询 sim_card 历史套餐
export function findSimCardHistoryPackages(id) {
  return request({
    url: `/sim_cards/${id}/history_packages`,
    method: 'get'
  })
}

// 查询 sim_card 单个周期序列序列信息
export function findSimCardProductCycleUsage(id, params) {
  return request({
    url: `/sim_cards/${id}/product_cycle_usage`,
    method: 'get',
    params: params
  })
}

// 更新 sim_card 运营商数据
export function updateSimCardCarrierData(id) {
  return request({
    url: `/sim_cards/${id}/carrier_data`,
    method: 'post'
  })
}

// 查询 sim_card 网络数据
export function findSimCardNetworkStatistics(id, params) {
  return request({
    url: `/sim_cards/${id}/network_statistics`,
    method: 'get',
    params: params
  })
}

// 查询 sim_card 历史轨迹数据
export function findSimCardHistoryLocation(id, params) {
  return request({
    url: `/sim_cards/${id}/history_location`,
    method: 'get',
    params: params
  })
}

// 批量验证搜索卡号
export function batchValidateSearchAgentSimCards(data) {
  return request({
    url: `/customer/sim_cards/search/batch_validate`,
    method: 'post',
    timeout: 600 * 1000,
    data
  })
}

// 分销商查看企业客户的卡
export function findAgencySimCardsByTopAgent(data) {
  return request({
    url: `/customer/sim_cards/agency/by_top_agent`,
    method: 'post',
    data
  })
}

// 查询客户的卡
export function exportSimCards(data) {
  return request({
    url: `/customer/sim_cards/export`,
    method: 'post',
    data
  })
}

// 分销商导出企业客户的卡
export function exportAgencySimCardsByTopAgent(data) {
  return request({
    url: `/customer/sim_cards/agency/by_top_agent/export`,
    method: 'post',
    data
  })
}

